import React, { Dispatch, SetStateAction, useContext, useMemo } from 'react';

import { Box, Button, Flex, useMultiStyleConfig } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';

import { useMediaUpdateMutation } from '../../../../gql/generated/graphql';

import { MediaModalType } from '../MediaModal';

import MediaForm from './MediaForm';

import { DealContext } from '../../../../libs/DealContext';
import { DealMediaEdit } from '../../../../types/media';
import { normalizeFileName } from '../../../../utils/files';
import { transformMetadata } from '../../../../utils/media';
import { dealMediaValidationSchema, parseFilename } from '../../utils';

interface MediaEditProps {
  showModal: MediaModalType;
  setShowModal: Dispatch<SetStateAction<MediaModalType>>;
  cancelFn: () => void;
  mediaRefetch: () => void;
  setIsExtractingData: Dispatch<SetStateAction<boolean>>;
  isExtractingData: boolean;
  variant?: 'reduced' | undefined;
}

const MediaEdit: React.FC<MediaEditProps> = ({
  showModal,
  setShowModal,
  cancelFn,
  mediaRefetch,
  setIsExtractingData,
  isExtractingData,
  variant,
}) => {
  const { deal } = useContext(DealContext);
  const [updateMedia] = useMediaUpdateMutation();

  const styles = useMultiStyleConfig('MediaEditForm', { variant });

  const { dealMedia } = showModal;

  const initialValues: DealMediaEdit = useMemo(() => {
    const { sourceFileName, friendlyName, fileExtension } = parseFilename(dealMedia?.key ?? '');

    return {
      id: dealMedia?.id,
      fileName: friendlyName,
      sourceFileName,
      extension: fileExtension,
      type: dealMedia?.type,
      metadata: dealMedia?.metadata,
      signed_url: dealMedia?.signed_url,
      source: dealMedia?.source,
    };
  }, [dealMedia]);

  const onSubmit = async (values: DealMediaEdit) => {
    try {
      if (!deal.id || !dealMedia?.key) {
        throw new Error();
      }

      await updateMedia({
        variables: {
          dealId: deal.id,
          oldKey: dealMedia?.key,
          newFileName: normalizeFileName(`${values.fileName}.${values.extension}`),
          type: values.type,
          metadata: transformMetadata(values.metadata),
          verified: showModal.dealMedia?.verified,
        },
      });

      mediaRefetch();
      toast.success('File updated!');
    } catch {
      toast.error('Unable to update file');
    }

    cancelFn();
  };

  return (
    <Box w="full">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={dealMediaValidationSchema}
        onSubmit={onSubmit}
        validateOnMount
      >
        {({ values, isSubmitting, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Flex __css={styles.flexContainer} justifyContent="space-between">
              <MediaForm
                showModal={showModal}
                setShowModal={setShowModal}
                setIsExtractingData={setIsExtractingData}
                variant={variant}
                initialMediaType={values.type}
              />
              <Box __css={styles.inlineSaveButtonContainer}>
                <Button
                  variant="secondary"
                  size="sm"
                  my={0}
                  type="submit"
                  isLoading={isSubmitting || isExtractingData}
                >
                  SAVE
                </Button>
              </Box>
            </Flex>

            <Flex __css={styles.formButtons}>
              <Button
                variant="secondary"
                type="submit"
                isLoading={isSubmitting || isExtractingData}
              >
                SAVE
              </Button>
              <Button variant="secondary" onClick={cancelFn} isDisabled={isSubmitting}>
                CANCEL
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default MediaEdit;
