import { FC, useContext, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { Button, useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  BootReason,
  BootReasonsEnum,
  Deal,
  DealStateEnum,
  estimateUpsert,
  isClosed,
} from '../../gql/dealGql';
import {
  DealUpdateRequestBootMutationVariables,
  useDealUpdateRequestBootMutation,
  usePayoffUpdateMutation,
} from '../../gql/generated/graphql';

import Modal from '../../components/shared/Modal';

import { PermissionEnum } from '../../constants/permissions';
import ROUTES from '../../constants/routes';
import { useLienholders } from '../../hooks/useLienholders';
import { DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';
import { AbilityContext, ModalContext } from '../../libs/contextLib';
import { cleanDealForEstimateUpsert } from '../../utils/deals';

const DisabledLienholderModal: FC = () => {
  const history = useHistory();
  const { deal } = useContext(DealContext);
  const { setModal } = useContext(ModalContext);
  const abilities = useContext(AbilityContext);
  const hasBootPermission = abilities.has(PermissionEnum.BootDeal);

  const { values, setSubmitting } = useFormikContext<Deal>();
  const bootAlreadyRequested =
    values.request_boot && values.boot_reason?.reason === BootReasonsEnum.LienholderNotApproved;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { options: allLienholdersOptions } = useLienholders({
    state: values.customer?.address?.state,
    make: values.car?.make,
  });

  const [upsertEstimate] = useMutation<{ estimateUpsert: Deal }>(estimateUpsert);
  const [requestBoot] = useDealUpdateRequestBootMutation();
  const [updatePayoff] = usePayoffUpdateMutation();

  const createDealOrUpdatePayoff = async () => {
    setSubmitting(true);

    if (!deal.id) {
      const cleanedValues = cleanDealForEstimateUpsert(values);

      const variables = {
        deal: cleanedValues,
      };
      try {
        const response = await upsertEstimate({
          variables,
        });
        setSubmitting(false);

        const { estimateUpsert: createdDeal } = response.data ?? {};
        return createdDeal?.id ?? null;
      } catch (e) {
        setSubmitting(false);

        logger.error('DisabledLienholderModal.tsx', 'Failed to create deal', variables, e);
        toast.error('Failed to create deal');

        return null;
      }
    }

    const variables = {
      payoff: values.car.payoff,
    };
    try {
      await updatePayoff({
        variables,
      });
      setSubmitting(false);

      return deal.id;
    } catch (e) {
      setSubmitting(false);

      logger.error('DisabledLienholderModal.tsx', 'Failed to update payoff', variables, e);
      toast.error('Failed to update payoff');

      return null;
    }
  };

  const handleClick = async () => {
    if (bootAlreadyRequested) {
      onClose();
      return;
    }

    const dealId = await createDealOrUpdatePayoff();
    if (!dealId) {
      return;
    }

    // Boot deal if the user has permission, otherwise request boot.
    const variables: DealUpdateRequestBootMutationVariables = {
      id: dealId,
      request_boot: !hasBootPermission,
      boot_reason: new BootReason(
        BootReasonsEnum.LienholderNotApproved,
        `Selected ${values.car.payoff.lienholder_name} is not approved`,
      ),
      boot_deal: hasBootPermission,
    };

    try {
      await requestBoot({
        variables,
      });

      toast.success(hasBootPermission ? 'Deal booted' : 'Deal boot requested');
      setModal({ PayoffInfo: false });
      onClose();
      history.push(ROUTES.DASHBOARD);
    } catch (e) {
      const message = `Failed to ${hasBootPermission ? 'boot deal' : 'request deal boot'}`;
      logger.error('DisabledLienholderModal.tsx', message, variables, e);
      toast.error(message);
    }
  };

  useEffect(() => {
    if (
      isClosed(values.state) ||
      values.state === DealStateEnum.Booted ||
      !values.car.payoff.lienholder_slug
    ) {
      return;
    }

    const selectedLienholderIsEnabled =
      allLienholdersOptions.find((option) => option.value === values.car.payoff.lienholder_slug)
        ?.enabled ?? true;
    if (!selectedLienholderIsEnabled) {
      onOpen();
    }
  }, [values.car.payoff.lienholder_slug, allLienholdersOptions]);

  return (
    <Modal
      title="Unsupported Lienholder"
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      canDismiss={false}
      showDivider={false}
      rightButtons={
        <Button variant={bootAlreadyRequested ? 'primary' : 'boot'} onClick={handleClick}>
          {bootAlreadyRequested ? 'CLOSE' : hasBootPermission ? 'BOOT' : 'REQUEST BOOT'}
        </Button>
      }
    >
      We are not currently supporting lease buyouts from{' '}
      {values.car.payoff.lienholder_name ? (
        <b>{values.car.payoff.lienholder_name}</b>
      ) : (
        <>the selected lienholder</>
      )}
      .{' '}
      {bootAlreadyRequested
        ? 'The deal has already been requested to be booted.'
        : `Please inform the customer and ${
            hasBootPermission ? 'boot' : 'request a boot for'
          } the deal.`}
    </Modal>
  );
};

export default DisabledLienholderModal;
