import {
  ChangeEvent,
  ChangeEventHandler,
  ComponentProps,
  FocusEvent,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  StyleProps,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { useField, useFormikContext } from 'formik';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BsCalendar, BsClock } from 'react-icons/bs';
import InputMask from 'react-input-mask';

import {
  formatDateTimeISOWithUTC,
  getDateWithLastDayOfTheMonth,
  getUTCDate,
} from '../../libs/utils';

type DatePickerProps = Partial<ReactDatePickerProps> & {
  name: string;
  topLabel?: string;
  leftLabel?: string;
  placeholder?: string;
  dateFormat?: string;
  showMonthYearPicker?: boolean;
  boxStyles?: StyleProps;
  labelStyles?: StyleProps;
  inputProps?: InputProps;
  errorStyles?: StyleProps;
  isDisabled?: boolean;
  formControlVariant?: 'inline' | 'reduced' | 'bold' | undefined;
  additionalHandleChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  isInvalid?: boolean;
  showErrorMessage?: boolean;
  valueFormat?: 'dateTime' | 'dateUTC' | 'dateTimeUTC';
  isRequired?: boolean;
};

/**
 * @param {'dateTime' | 'dateUTC' | 'dateTimeUTC'} valueFormat - Represents the value stored in the DB:
 * dateTime: `yyyy-MM-ddTHH:mm:ss.SSSZ` (keeps the timezone) | dateUTC: `yyyy-MM-dd` | dateTimeUTC: `yyyy-MM-ddT00:00:00.000Z`
 */
const DatePicker = ({
  name,
  topLabel,
  leftLabel,
  placeholder,
  dateFormat = 'MM/dd/yyyy',
  showMonthYearPicker,
  boxStyles,
  labelStyles,
  inputProps,
  errorStyles,
  isDisabled,
  formControlVariant,
  additionalHandleChange,
  isInvalid,
  showErrorMessage = true,
  valueFormat = 'dateTime',
  isRequired,
  ...rest
}: DatePickerProps) => {
  const datePickerRef = useRef<ReactDatePicker>(null);

  const { handleBlur } = useFormikContext();

  const [field, { error, touched, initialValue }, { setValue, setTouched }] = useField<
    Date | string | null | undefined
  >(name);
  const defaultIsInvalid = touched && !!error;

  const styles = useMultiStyleConfig('FormControl', { variant: formControlVariant });

  const { showTimeSelectOnly } = rest;
  const customInputProps: ComponentProps<typeof Input> =
    dateFormat === 'MM/dd/yyyy'
      ? {
          as: InputMask,
          mask: '99/99/9999',
          maskPlaceholder: 'mm/dd/yyyy',
          alwaysShowMask: true,
        }
      : {};

  const selectedDate = useMemo(() => {
    if (!field?.value) {
      return null;
    }
    if (valueFormat === 'dateUTC' || valueFormat === 'dateTimeUTC') {
      return getUTCDate(field.value);
    }
    return new Date(field.value);
  }, [field?.value]);

  const onChange = (value: Date | null) => {
    const newDate = showMonthYearPicker ? getDateWithLastDayOfTheMonth(value) : value;

    const getFormattedValue = () => {
      if (!newDate) {
        return null;
      }
      if (valueFormat === 'dateUTC') {
        return format(newDate, 'yyyy-MM-dd');
      }
      if (valueFormat === 'dateTimeUTC') {
        return formatDateTimeISOWithUTC(newDate);
      }
      return newDate;
    };

    const formattedValue = getFormattedValue();
    setValue(formattedValue);
    setTimeout(() => setTouched(true), 0);
  };

  useEffect(() => {
    if (field?.value === initialValue && !touched) {
      return;
    }

    additionalHandleChange?.({
      target: { name, value: field?.value },
      currentTarget: { name, value: field?.value },
    } as unknown as ChangeEvent<HTMLInputElement>);

    // ReactDatePicker doesn't trigger onBlur when selecting a date from the calendar.
    handleBlur({ target: { name }, currentTarget: { name } } as FocusEvent<HTMLInputElement>);
  }, [field?.value]);

  return (
    <Box {...boxStyles}>
      <FormControl isInvalid={isInvalid ?? defaultIsInvalid} sx={styles.control}>
        {topLabel ? (
          <FormLabel whiteSpace="nowrap" fontSize="sm" {...labelStyles} sx={styles.label}>
            {topLabel}
            {isRequired ? ' *' : ''}
          </FormLabel>
        ) : null}
        {leftLabel ? (
          <FormLabel whiteSpace="nowrap" {...labelStyles} sx={styles.label}>
            <Text>
              {leftLabel}
              {isRequired ? ' *' : ''}
            </Text>
          </FormLabel>
        ) : null}

        <InputGroup sx={styles.input}>
          <ReactDatePicker
            placeholderText={placeholder}
            selected={selectedDate}
            onChange={onChange}
            dateFormat={dateFormat}
            showMonthYearPicker={showMonthYearPicker}
            showFourColumnMonthYearPicker={showMonthYearPicker}
            customInput={
              <Input
                borderLeftRadius={leftLabel ? 0 : undefined}
                {...inputProps}
                {...customInputProps}
              />
            }
            ref={datePickerRef}
            autoComplete="false"
            disabled={isDisabled}
            showPopperArrow={false}
            popperProps={{ strategy: 'fixed' }}
            {...rest}
          />

          <InputRightElement>
            <IconButton
              aria-label="open"
              icon={showTimeSelectOnly ? <BsClock /> : <BsCalendar />}
              size="xs"
              variant="ghost"
              onClick={() => datePickerRef?.current?.setOpen(true)}
              cursor={isDisabled ? 'not-allowed' : 'pointer'}
            />
          </InputRightElement>
        </InputGroup>

        {showErrorMessage ? (
          <FormErrorMessage {...errorStyles} sx={styles.error}>
            {error}
          </FormErrorMessage>
        ) : null}
      </FormControl>
    </Box>
  );
};

export default DatePicker;
