import { Button } from '@chakra-ui/react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import {
  Auth0RoleInput,
  HuntGroup,
  InputMaybe,
  useCreateUserMutation,
  useGetUserCreationDataQuery,
} from '../../../gql/generated/graphql';
import { Pod } from '../../../gql/podGql';

import Modal, { LEModalProps } from '../../../components/shared/Modal';
import { createUserValidationSchema } from '../utils';

import CreateUserModalForm from './CreateUserModalForm';

import { logger } from '../../../libs/Logger';

export type CreateUserModalFormikFields = {
  auth0Roles: InputMaybe<Auth0RoleInput>[];
  email: string;
  huntGroups: HuntGroup[];
  name: string;
  password: string;
  phoneNumber: string;
  pods: Pod[];
};

const CreateUserModal = ({ isOpen, onClose, ...rest }: LEModalProps) => {
  const [createUser, { loading }] = useCreateUserMutation();
  const { data: userCreationData } = useGetUserCreationDataQuery({
    skip: !isOpen,
  });

  const handleCloseModal = (formikHelpers: { resetForm: () => void }) => {
    onClose();
    formikHelpers.resetForm();
  };

  const initialValues: CreateUserModalFormikFields = {
    auth0Roles: [],
    email: '',
    huntGroups: [],
    name: '',
    password: '',
    phoneNumber: '',
    pods: [],
  };

  const onSave = async (
    values: CreateUserModalFormikFields,
    formikHelpers: { resetForm: () => void },
  ) => {
    try {
      const { data } = await createUser({
        variables: {
          createUserInput: {
            auth0Roles: values.auth0Roles ?? [],
            email: values.email,
            huntGroupIds: values.huntGroups?.map((huntGroup) => huntGroup.id),
            name: values.name,
            password: values.password,
            phone_number: values.phoneNumber,
            podIds: values.pods?.map((pod) => pod.id),
          },
        },
      });

      if (data?.createUser) {
        handleCloseModal({ resetForm: formikHelpers.resetForm });
        toast.success('User created successfully');
      }
    } catch (e) {
      const error = e as Error;
      toast.error(`Failed to create user: ${error.message}`);
      logger.error('CreateUserModal.tsx', 'Failed to create user', null, e);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createUserValidationSchema}
      validateOnMount
      onSubmit={onSave}
      enableReinitialize
    >
      {({ isValid, resetForm, values }) => {
        return (
          <Modal
            {...rest}
            title="Create New User"
            size="3xl"
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={() => {
              handleCloseModal({ resetForm });
            }}
            leftButtons={
              <Button
                variant="warning"
                onClick={() => {
                  handleCloseModal({ resetForm });
                }}
              >
                CANCEL
              </Button>
            }
            rightButtons={
              <Button
                variant="primary"
                type="submit"
                isDisabled={!isValid}
                isLoading={loading}
                onClick={async () => {
                  await onSave(values, { resetForm });
                }}
              >
                ADD USER
              </Button>
            }
          >
            <CreateUserModalForm
              huntGroups={(userCreationData?.getUserCreationData?.huntGroups || []) as HuntGroup[]}
              pods={(userCreationData?.getUserCreationData?.pods || []) as Pod[]}
            />
          </Modal>
        );
      }}
    </Formik>
  );
};

export default CreateUserModal;
