import React, { Dispatch, SetStateAction, useEffect } from 'react';

import { Flex, useMultiStyleConfig } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { DealMediaSourceEnum, DealMediaTypeEnum, Maybe } from '../../../../gql/generated/graphql';

import Autocomplete from '../../../shared/Autocomplete';
import Input from '../../../shared/Input';
import { MediaModalType } from '../MediaModal';

import { DealMediaTypeOptions } from '../../../../constants/media';
import useLazyExtractDataFromFile from '../../../../hooks/useLazyExtractDataFromFile';
import { DealMediaEdit } from '../../../../types/media';
import { normalizeFileName } from '../../../../utils/files';

interface MediaFormProps {
  showModal: MediaModalType;
  setShowModal: Dispatch<SetStateAction<MediaModalType>>;
  setIsExtractingData: Dispatch<SetStateAction<boolean>>;
  variant?: 'reduced' | undefined;
  initialMediaType: Maybe<DealMediaTypeEnum>;
}

const MediaForm: React.FC<MediaFormProps> = ({
  showModal,
  setShowModal,
  setIsExtractingData,
  variant,
  initialMediaType,
}) => {
  const { handleChange, values } = useFormikContext<DealMediaEdit>();

  const { extractDataFromFile, loading: isExtractingData } = useLazyExtractDataFromFile();

  const styles = useMultiStyleConfig('MediaForm', { variant });
  const formControlVariant = variant === 'reduced' ? 'reduced' : 'inline';

  useEffect(() => {
    setIsExtractingData(isExtractingData);
  }, [isExtractingData]);

  useEffect(() => {
    if (values.type || values.fileName) {
      setTimeout(
        () =>
          setShowModal({
            ...showModal,
            dealMedia: {
              ...showModal.dealMedia,
              type: values.type,
              fileName: values.fileName,
              sourceFileName: normalizeFileName(`${values.fileName}.${values.extension}`),
            },
          }),
        0,
      );
    }
  }, [values.type, values.fileName]);

  return (
    <Flex __css={styles.flexContainer}>
      <Flex __css={styles.inputsContainer}>
        <Input
          formControlVariant={formControlVariant}
          label="File Name"
          name="fileName"
          rightAddon={values.extension ? `.${values.extension}` : ''}
          onChange={handleChange}
          isDisabled={values.source === DealMediaSourceEnum.System}
        />
        <Autocomplete
          formControlVariant={formControlVariant}
          label="What is it?"
          name="type"
          options={DealMediaTypeOptions}
          defaultValue={initialMediaType ?? undefined}
          additionalOnChange={async (newValue) => {
            const extractedMetadata = await extractDataFromFile(
              newValue?.value as DealMediaTypeEnum,
              showModal.dealMedia?.metadata,
              showModal.dealMedia?.key ?? '',
            );

            setShowModal({
              ...showModal,
              dealMedia: {
                ...showModal.dealMedia,
                verified: false,
                type: newValue?.value as DealMediaTypeEnum,
                metadata: extractedMetadata,
              },
            });
          }}
        />
      </Flex>
    </Flex>
  );
};

export default MediaForm;
