import { addDays, isAfter } from 'date-fns';
import * as Yup from 'yup';

import { CustomDates } from '../../gql/dealDatesGql';
import { DealStateEnum } from '../../gql/dealGql';

import { getUTCDate } from '../utils';

export const validateTodayOrEarlier = (value: Date | null | undefined) => {
  if (!value) {
    return true;
  }

  return getUTCDate(value) <= getUTCDate(new Date());
};

export const getTitleReceivedDateValidation = (customDates?: CustomDates | null) =>
  Yup.date()
    .optional()
    .nullable()
    .typeError('Invalid Date')
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .test('testCantBeFuture', `Can't be in the future`, validateTodayOrEarlier)
    .test('testAfterCustomSigned', '', (titleReceivedDate, context) => {
      if (!titleReceivedDate) {
        return true;
      }

      const currentCustomDates = (context.parent as CustomDates) ?? customDates;
      const { [DealStateEnum.Signed]: signedDate } = currentCustomDates ?? {};

      if (!signedDate) {
        return true;
      }

      const utcSignedDate = getUTCDate(signedDate);
      const utcTitleReceivedDate = getUTCDate(titleReceivedDate);
      if (utcTitleReceivedDate >= utcSignedDate) {
        return true;
      }

      const validationError: Yup.ValidationError = context.createError({
        message: `Should be after ${utcSignedDate.toLocaleDateString()}`,
        path: context.path,
      });

      return validationError;
    });

export const validateFuture = (value?: Date | null) => !value || value >= new Date();

export const validateDateIsAfter = (value?: Date | string | null, days?: number | null) => {
  const parsedValue = typeof value === 'string' ? new Date(value) : value;
  if (!parsedValue) {
    return true;
  }

  const futureDate = addDays(new Date(), days ?? 0);
  return isAfter(parsedValue, futureDate);
};
