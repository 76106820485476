import React, { Dispatch, SetStateAction, useContext, useMemo, useRef, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { Box, Button, Flex } from '@chakra-ui/react';
import { FieldArray, FieldArrayRenderProps, Form, Formik } from 'formik';
import { toast } from 'react-toastify';

import {
  DealMediaTypeEnum,
  GetUploadUrlDocument,
  Maybe,
  useMediaInsertMutation,
} from '../../../../gql/generated/graphql';

import { MediaModalType } from '../MediaModal';

import MediaUploadForm from './MediaUploadForm';

import useLazyExtractDataFromFile from '../../../../hooks/useLazyExtractDataFromFile';
import { DealContext } from '../../../../libs/DealContext';
import { DealMediaEdit, GetUploadUrlResult } from '../../../../types/media';
import { getFileNameAndExtension, normalizeFileName } from '../../../../utils/files';
import { transformMetadata } from '../../../../utils/media';
import Dropzone from '../../../Dropzone';
import { acceptedFileTypes, validationSchema } from '../../utils';

interface MediaUploadProps {
  cancelFn: () => void;
  mediaRefetch: () => void;
  showModal: MediaModalType;
  setShowModal: Dispatch<SetStateAction<MediaModalType>>;
  initialMediaType?: Maybe<DealMediaTypeEnum>;
}

const MediaUpload: React.FC<MediaUploadProps> = ({
  cancelFn,
  mediaRefetch,
  showModal,
  setShowModal,
  initialMediaType,
}) => {
  const { deal } = useContext(DealContext);

  const client = useApolloClient();
  const [mediaInsert] = useMediaInsertMutation();
  const { extractDataFromFile } = useLazyExtractDataFromFile();

  const [uploading, setUploading] = useState(false);
  const arrayHelpersRef = useRef<FieldArrayRenderProps>();

  const initialValues: {
    dealMedia: DealMediaEdit[];
  } = useMemo(
    () => ({
      dealMedia: [],
    }),
    [],
  );

  const onDrop = async (acceptedFiles: File[]) => {
    const newMedia: DealMediaEdit[] = await Promise.all(
      acceptedFiles.map(async (file) => {
        const normalizedFileName = normalizeFileName(file.name);
        const { fileName, extension } = getFileNameAndExtension(normalizedFileName);

        return {
          selectedFile: file,
          sourceFileName: normalizedFileName,
          fileName,
          extension,
          type: null,
        };
      }),
    );

    newMedia.forEach((file) => {
      arrayHelpersRef.current?.push(file);
    });
  };

  const onSubmit = async (values: { dealMedia: DealMediaEdit[] }) => {
    if (!values.dealMedia.length) {
      return;
    }

    setUploading(true);
    try {
      const dealId = deal.id;
      if (!dealId) {
        throw new Error();
      }

      await Promise.all(
        values.dealMedia.map(async (file) => {
          const { data } = await client.query<GetUploadUrlResult>({
            query: GetUploadUrlDocument,
            variables: {
              dealId,
              fileName: normalizeFileName(`${file.fileName}.${file.extension}`),
            },
          });

          const { url, key } = data.getUploadUrl;
          await fetch(url, {
            method: 'PUT',
            body: file.selectedFile,
          });

          const extractedMetadata = await extractDataFromFile(
            file.type,
            file.metadata,
            file.selectedFile,
          );

          return mediaInsert({
            variables: {
              key,
              dealId,
              type: file.type,
              metadata: transformMetadata(extractedMetadata),
            },
          });
        }),
      );

      mediaRefetch();
      toast.success('Files uploaded!');
    } catch {
      toast.error('Unable to upload files');
    }

    setUploading(false);
    cancelFn();
  };

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Flex direction="column" justifyContent="center">
              <FieldArray
                name="dealMedia"
                render={(arrayHelpers) => {
                  arrayHelpersRef.current = arrayHelpers;
                  const { remove } = arrayHelpers;

                  if (values.dealMedia.length === 0) {
                    return null;
                  }

                  return (
                    <Flex direction="column" ml={3} flex={1}>
                      {values.dealMedia.map((media, index) => (
                        <MediaUploadForm
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          showModal={showModal}
                          setShowModal={setShowModal}
                          index={index}
                          remove={remove}
                          initialMediaType={initialMediaType}
                        />
                      ))}
                    </Flex>
                  );
                }}
              />
              <Box maxW="350px" margin="auto">
                <Dropzone
                  onDrop={onDrop}
                  multiple
                  accept={acceptedFileTypes.join(', ')}
                  showFiles={false}
                />
              </Box>
            </Flex>
            <Flex direction="row" justifyContent="end" mt={5}>
              {values.dealMedia.length > 0 && (
                <Button type="submit" isLoading={uploading} loadingText="UPLOAD">
                  UPLOAD
                </Button>
              )}
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default MediaUpload;
