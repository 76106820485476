import { useMemo, useState } from 'react';

import { Flex, Text, useDisclosure } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { Maybe, UserWithRole, useUsersWithRoleQuery } from '../../gql/generated/graphql';

import Loader from '../../components/Loader';

import HomeCard from './HomeCard';
import UserSettingsModal from './UserSettingsModal';

import { LDFlags } from '../../constants/experiments';
import { RoleEnum } from '../../constants/permissions';
import useFlag from '../../hooks/useFlag';

const FinancialSpecialist = () => {
  const [selectedUser, setSelectedUser] = useState<UserWithRole | null>(null);

  const autoAssigningDealsFlag = useFlag(LDFlags.AUTO_ASSIGNING_DEALS);

  const userSettingsModal = useDisclosure();

  const {
    data: users,
    refetch: refetchUsers,
    loading,
  } = useUsersWithRoleQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      toast.error(`Couldn't load Users. Please refresh. Error: ${error.message}`);
    },
  });

  const financialSpecialists: Maybe<UserWithRole>[] = useMemo(
    () =>
      users?.usersWithRole
        ?.filter((user) => user?.role === RoleEnum.FinancialSpecialist)
        .sort((a, b) => a?.name?.localeCompare(b?.name ?? '') ?? 1) || [],
    [users],
  );

  return (
    <>
      {autoAssigningDealsFlag ? (
        <Flex direction="column">
          <Text fontSize="24px" padding="22px 25px">
            Financial Specialists
          </Text>

          {financialSpecialists.length > 0 ? (
            financialSpecialists.map((financialSpecialist) => (
              <HomeCard
                key={financialSpecialist?.id}
                text={financialSpecialist?.name || ''}
                borderLeftColor={financialSpecialist?.pods?.[0]?.color || ''}
                handleGearClick={() => {
                  if (financialSpecialist) {
                    setSelectedUser(financialSpecialist);
                  }
                  userSettingsModal.onOpen();
                }}
              />
            ))
          ) : (
            <Text fontWeight="normal" fontStyle="italic">
              No Financial Specialists
            </Text>
          )}
          <UserSettingsModal
            isOpen={userSettingsModal.isOpen}
            onClose={userSettingsModal.onClose}
            user={selectedUser}
            refetchUsers={refetchUsers}
          />
        </Flex>
      ) : null}
      <Loader isLoading={loading} />
    </>
  );
};

export default FinancialSpecialist;
