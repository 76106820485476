import { ApolloError, FetchResult } from '@apollo/client';
import Big from 'big.js';
import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';

import { Car } from '../gql/carGql';
import { Customer } from '../gql/customerGql';
import { Deal, DealStateEnum, dealPodAndUserIdKeys } from '../gql/dealGql';
import { DOC_FEE, ProcessorEnum, TITLE_FEE } from '../gql/financialInfoGql';
import {
  DealSource,
  DealType,
  FollowUp,
  FollowUpStatusEnum,
  CreditScore as GeneratedCreditScore,
  Deal as GeneratedDeal,
  GetFeesQueryVariables,
  Maybe,
  PaymentEstimateInput,
  StateAbbreviation,
  TemporaryData,
  TtFuelType,
  TtGetFeesSourceType,
} from '../gql/generated/graphql';
import { User } from '../gql/userGql';

import { StructuringFollowUpDeals } from '../components/StructuringFollowUp/StructuringFollowUpTable';
import { LE_TIME_ZONE } from '../constants/LeaseEndInfo';
import { PermissionEnum } from '../constants/permissions';

import { transformVehicleType } from './cars';

export const getDealAgeColor = (age: number) => {
  if (age >= 0 && age <= 30) {
    return 'green';
  }

  if (age >= 31 && age <= 45) {
    return 'primary';
  }

  if (age >= 46 && age <= 75) {
    return 'americanOrange';
  }

  return 'errorsRed';
};

export const getFollowUpColor = (followUp: FollowUp) => {
  if (!followUp.status) {
    return '';
  }

  const colorMap: Record<FollowUpStatusEnum, string> = {
    [FollowUpStatusEnum.Scheduled]: 'primary',
    [FollowUpStatusEnum.Upcoming]: 'americanOrange',
    [FollowUpStatusEnum.Due]: 'errorsRed',
    [FollowUpStatusEnum.Completed]: '',
    [FollowUpStatusEnum.Deleted]: '',
  };

  return colorMap[followUp.status];
};

export const getDealAge = (date?: string, timezone?: string) => {
  if (!date) {
    return 0;
  }

  const timeZone = timezone || LE_TIME_ZONE;

  const today = new Date(new Date().toLocaleString('en-US', { timeZone }));

  const diffTime = Math.abs(today.getTime() - new Date(date).getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return Number(diffDays);
};

export const showCreatePacketAlert = (deal: Deal, user: User) =>
  !!(
    deal.state === DealStateEnum.WaitingForTitle &&
    deal.deal_dates?.custom_dates?.title_received &&
    (deal.title_clerk_id === user.id || deal.title_clerk2_id === user.id)
  );

export const handleClaimDealError = (e: unknown) => {
  if (e instanceof ApolloError) {
    toast.error(e.message);
    return;
  }

  toast.error('Failed to assign deal.  Please refresh the page');
};

export interface ProcessorOption {
  label: ProcessorEnum;
  value: ProcessorEnum;
}

export const processorOptions: ProcessorOption[] = Object.values(ProcessorEnum).map((value) => ({
  label: value,
  value,
}));

export const canSetProcessor = (dealState: DealStateEnum) => {
  const canSetProcessorStates = [
    DealStateEnum.WaitingForTitle,
    DealStateEnum.TitleReceived,
    DealStateEnum.SentToProcessor,
    DealStateEnum.Finalized,
  ];

  return canSetProcessorStates.includes(dealState);
};

export const cleanDealForUpdate = (deal: Deal) => {
  const newDeal = cloneDeep(deal);

  delete newDeal.consents;
  delete newDeal.tags;
  delete newDeal.setter;
  delete newDeal.closer;
  delete newDeal.closer2;
  delete newDeal.structuring_manager;
  delete newDeal.funding_clerk;
  delete newDeal.title_clerk;
  delete newDeal.title_clerk2;

  // Avoids sending null values that update assignments
  // and allow users to claim the deal again
  dealPodAndUserIdKeys.forEach((property) => {
    if (!newDeal[property]) {
      delete newDeal[property];
    }
  });

  return newDeal;
};

export const cleanDealForEstimateUpsert = (deal: Deal) => {
  const cleanDeal = cleanDealForUpdate(deal);
  delete cleanDeal.customer.ssn_last_4;
  delete cleanDeal.customer.ssn_last_6;
  delete cleanDeal.follow_up;
  return cleanDeal;
};

export const parseTempInfoAsDeal = (tempData: TemporaryData) => {
  const deal = new Deal();
  deal.car = new Car();
  deal.customer = new Customer();

  if (!tempData) {
    return deal;
  }

  const {
    year,
    make,
    model,
    vin,
    mileage,
    lh_account_number: account_number,
    lienholder: lienholder_name,
    first_name,
    last_name,
    dob,
    phone_number,
    zip,
  } = tempData;

  Object.assign(deal, {
    car: {
      year,
      make,
      model,
      vin,
      mileage,
      payoff: { lienholder_name, account_number },
    },
    customer: {
      first_name,
      last_name,
      dob,
      phone_number,
      address: { zip },
    },
  });

  return deal;
};

type MapDealToGetTTFeesVariablesOptions =
  | {
      ttEndpoint: 'createTransaction';
    }
  | {
      ttEndpoint: 'getFees';
      ttGetFeesSource: TtGetFeesSourceType;
    };

export const mapDealToGetTTFeesVariables = (
  deal: Deal,
  options: MapDealToGetTTFeesVariablesOptions,
): GetFeesQueryVariables => {
  const { ttEndpoint } = options;
  const ttGetFeesSource = ttEndpoint === 'getFees' ? options.ttGetFeesSource : undefined;

  const { customer, car, financial_info } = deal;

  return {
    ...(ttEndpoint === 'getFees' ? { uniqueId: String(deal.id) } : {}),
    state: customer.address.state as StateAbbreviation,
    previouslyTitledState: (car.license_plate_state || customer.address.state) as StateAbbreviation,
    city: customer.address.city ?? '',
    county: customer.address.county ?? '',
    zip: customer.address.zip,
    vin: car.vin,
    make: car.make,
    model: car.model,
    year: parseInt(car.year, 10),
    vehicleType: transformVehicleType(car.vehicle_type ?? ''),
    fuelType:
      car.fuel_type && TtFuelType[car.fuel_type as TtFuelType]
        ? (car.fuel_type as TtFuelType)
        : TtFuelType.Gasoline,
    firstName: customer.first_name,
    lastName: customer.last_name,
    retailBookValue: car.retail_book_value ?? 0,
    bookValue: car.book_value ?? 0,
    docFee: financial_info?.doc_fee ?? DOC_FEE,
    payoff: car.payoff.vehicle_payoff ?? 0,
    estimatedPayoff: car.payoff.estimated_payoff ?? 0,
    ssn: customer.ssn || '111-11-1111',
    warranty: financial_info?.vsc_price ?? 0,
    ...(ttEndpoint === 'getFees' ? { source: ttGetFeesSource } : {}),
  };
};

export const mapDealToGetPaymentEstimateVariables = (
  deal: GeneratedDeal,
  ttGetFeesSource: TtGetFeesSourceType,
): { data: PaymentEstimateInput } => ({
  data: {
    payoff: deal?.car?.payoff?.vehicle_payoff ?? 0,
    creditScore: GeneratedCreditScore.Good,
    term: Number(deal.financial_info?.term),
    moneyDown: deal.financial_info?.money_down ?? 0,
    zipCode: deal?.customer?.address?.zip as string,
    feesInputFromDD: {
      ...mapDealToGetTTFeesVariables(deal as Deal, {
        ttEndpoint: 'getFees',
        ttGetFeesSource,
      }),
      docFee: DOC_FEE + TITLE_FEE, // title is included in doc fee for payment estimation purposes
    },
    financialInfoId: deal?.financial_info?.id,
  },
});

export const getIsClickable = (
  deal: Maybe<Deal | GeneratedDeal | StructuringFollowUpDeals[number]>,
  permissions: Set<PermissionEnum>,
) => {
  if (!deal) {
    return false;
  }
  if (
    [DealStateEnum.Floor, DealStateEnum.StructuringInProgress].some((state) => state === deal.state)
  ) {
    return false;
  }

  const userIsAdmin = permissions.has(PermissionEnum.SuperUser);
  const userIsCloserOrFinancialSpecialist =
    permissions.has(PermissionEnum.Closer) || permissions.has(PermissionEnum.FinancialSpecialist);
  const userCanBuildDeal = permissions.has(PermissionEnum.BuildDeal);

  const dealIsWeb = deal.source === DealSource.Web;
  const dealIsClosingOrClosed =
    deal.state === DealStateEnum.Closing || deal.state === DealStateEnum.Closed;
  const dealIsStructuring = deal.state === DealStateEnum.Structuring;

  const dealIsClaimedByCloser = !!(deal.closer_id || deal.closer?.id);
  const dealIsClaimedByStructuringManager = !!(
    deal.structuring_manager_id || deal.structuring_manager?.id
  );

  if (
    dealIsStructuring &&
    !userIsAdmin &&
    (!userCanBuildDeal || !dealIsClaimedByStructuringManager)
  ) {
    return false;
  }
  if (
    dealIsWeb &&
    dealIsClosingOrClosed &&
    userIsCloserOrFinancialSpecialist &&
    !userIsAdmin &&
    !dealIsClaimedByCloser
  ) {
    return false;
  }
  return true;
};

export const setInsertedIds = (
  values: Deal,
  isCobuyer: boolean,
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void,
  res: FetchResult<{ creditAppUpsert: Deal }>,
) => {
  if (
    !values?.customer?.proof_of_insurance?.id &&
    res?.data?.creditAppUpsert?.customer?.proof_of_insurance?.id
  ) {
    setFieldValue(
      'customer.proof_of_insurance.id',
      res.data?.creditAppUpsert?.customer?.proof_of_insurance?.id,
    );
  }

  if (!values?.financial_info?.id && res?.data?.creditAppUpsert?.financial_info?.id) {
    setFieldValue('financial_info.id', res.data?.creditAppUpsert?.financial_info.id);
  }

  const personKey = isCobuyer ? 'cobuyer' : 'customer';
  const personValues = values?.[personKey];
  const personRes = res?.data?.creditAppUpsert?.[personKey];

  if (personValues?.id !== personRes?.id) {
    setFieldValue(`${personKey}.id`, personRes?.id);
  }
  if (personValues?.address?.id !== personRes?.address?.id) {
    setFieldValue(`${personKey}.address.id`, personRes?.address?.id);
  }
  if (personValues?.prev_address?.id !== personRes?.prev_address?.id) {
    setFieldValue(`${personKey}.prev_address.id`, personRes?.prev_address?.id);
  }
  if (personValues?.employment?.id !== personRes?.employment?.id) {
    setFieldValue(`${personKey}.employment.id`, personRes?.employment?.id);
  }
  if (personValues?.prev_employment?.id !== personRes?.prev_employment?.id) {
    setFieldValue(`${personKey}.prev_employment.id`, personRes?.prev_employment?.id);
  }
};

export const getLtvRatio = (loanValue: number, bookValue: number): number =>
  bookValue ? new Big(loanValue).div(bookValue).round(2).toNumber() : 0;

type Field = 'oldPayment';
type FieldDealTypeLabel = {
  [K in Extract<DealType, DealType.Buyout>]: string;
} & Partial<{
  [K in Exclude<DealType, DealType.Buyout>]: string;
}>;

const fieldLabelMap: Record<Field, FieldDealTypeLabel> = {
  oldPayment: {
    buyout: 'Old Lease Payment',
    refi: 'Old Payment',
  },
};

export const getFieldLabel = (field: Field, dealType?: DealType): string =>
  dealType
    ? fieldLabelMap[field][dealType] || fieldLabelMap[field].buyout
    : fieldLabelMap[field].buyout;
