import { DealMedia as GeneratedDealMedia } from '../gql/generated/graphql';

import { MismatchSelectionEnum } from '../constants/media';

// Driver License Metadata
export type DriverLicenseMetadata = {
  name?: string;
  nameVerified?: boolean;
  nameMismatchSelection?: MismatchSelectionEnum;
  address?: string;
  addressVerified?: boolean;
  addressMismatchSelection?: MismatchSelectionEnum;
  address2?: string;
  address2Verified?: boolean;
  city?: string;
  cityVerified?: boolean;
  state?: string;
  stateVerified?: boolean;
  zip?: string;
  zipVerified?: boolean;
  expirationDate?: string;
  expirationDateVerified?: boolean;
};

export type DriverLicenseMetadataValueFields = keyof Pick<
  DriverLicenseMetadata,
  'name' | 'address' | 'address2' | 'city' | 'state' | 'zip' | 'expirationDate'
>;

export type DriverLicenseMetadataVerifiedFields = keyof Pick<
  DriverLicenseMetadata,
  | 'nameVerified'
  | 'addressVerified'
  | 'address2Verified'
  | 'cityVerified'
  | 'stateVerified'
  | 'zipVerified'
  | 'expirationDateVerified'
>;

export type DriverLicenseMetadataMismatchFields = keyof Pick<
  DriverLicenseMetadata,
  'nameMismatchSelection' | 'addressMismatchSelection'
>;

export const DriverLicenseVerifiedFieldMap = new Map<
  DriverLicenseMetadataValueFields,
  DriverLicenseMetadataVerifiedFields
>([
  ['name', 'nameVerified'],
  ['address', 'addressVerified'],
  ['address2', 'address2Verified'],
  ['city', 'cityVerified'],
  ['state', 'stateVerified'],
  ['zip', 'zipVerified'],
  ['expirationDate', 'expirationDateVerified'],
]);

export const DriverLicenseMismatchFieldMap = new Map<
  DriverLicenseMetadataValueFields,
  DriverLicenseMetadataMismatchFields
>([
  ['name', 'nameMismatchSelection'],
  ['address', 'addressMismatchSelection'],
]);

// Insurance Card Metadata
export type InsuranceCardMetadata = {
  name?: string;
  nameVerified?: boolean;
  state?: string;
  stateVerified?: boolean;
  vin?: string;
  vinVerified?: boolean;
  expirationDate?: string;
  expirationDateVerified?: boolean;
  cobuyerOnInsurance?: string;
  insuranceCompany?: string;
  insuranceCompanyVerified?: boolean;
  policyNo?: string;
  policyNoVerified?: boolean;
};

export type InsuranceCardMetadataValueFields = keyof Pick<
  InsuranceCardMetadata,
  | 'name'
  | 'state'
  | 'vin'
  | 'expirationDate'
  | 'cobuyerOnInsurance'
  | 'insuranceCompany'
  | 'policyNo'
>;

export type InsuranceCardMetadataVerifiedFields = keyof Pick<
  InsuranceCardMetadata,
  | 'nameVerified'
  | 'stateVerified'
  | 'vinVerified'
  | 'expirationDateVerified'
  | 'insuranceCompanyVerified'
  | 'policyNoVerified'
>;

export const InsuranceCardVerifiedFieldMap = new Map<
  InsuranceCardMetadataValueFields,
  InsuranceCardMetadataVerifiedFields
>([
  ['name', 'nameVerified'],
  ['state', 'stateVerified'],
  ['vin', 'vinVerified'],
  ['expirationDate', 'expirationDateVerified'],
  ['insuranceCompany', 'insuranceCompanyVerified'],
  ['policyNo', 'policyNoVerified'],
]);

// Registration Metadata
export type RegistrationMetadata = {
  expirationDate?: string;
  expirationDateVerified?: boolean;
  expirationDate60Days?: string;
  expirationDate60DaysVerified?: boolean;
  expirationDate90Days?: string;
  expirationDate90DaysVerified?: boolean;
};

export type RegistrationMetadataValueFields = keyof Pick<
  RegistrationMetadata,
  'expirationDate' | 'expirationDate60Days' | 'expirationDate90Days'
>;

export type RegistrationMetadataVerifiedFields = keyof Pick<
  RegistrationMetadata,
  'expirationDateVerified' | 'expirationDate60DaysVerified' | 'expirationDate90DaysVerified'
>;

export const RegistrationVerifiedFieldMap = new Map<
  RegistrationMetadataValueFields,
  RegistrationMetadataVerifiedFields
>([
  ['expirationDate', 'expirationDateVerified'],
  ['expirationDate60Days', 'expirationDate60DaysVerified'],
  ['expirationDate90Days', 'expirationDate90DaysVerified'],
]);

// Deal Media
export type Metadata = DriverLicenseMetadata & InsuranceCardMetadata & RegistrationMetadata;

// TODO: move metadata types to the BE
export type DealMedia = GeneratedDealMedia & {
  metadata?: Metadata;
  verified_digital_signature_required?: boolean;
  notary_required?: boolean;
};

export type DealMediaEdit = {
  selectedFile?: File;
  sourceFileName?: string;
  fileName?: string;
  extension?: string;
} & DealMedia;

// Upload URL
export type GetUploadUrl = {
  key: string;
  url: string;
};

export type GetUploadUrlResult = {
  getUploadUrl: GetUploadUrl;
};
