import { Dispatch, KeyboardEvent, SetStateAction, useContext, useState } from 'react';

import { useApolloClient, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Input,
  Spinner,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { BsLink45Deg } from 'react-icons/bs';
import { MdAdd } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Customer } from '../../../gql/customerGql';
import { Deal } from '../../../gql/dealGql';
import {
  DealMediaTypeEnum,
  GetUploadUrlDocument,
  Maybe,
  useMediaInsertMutation,
} from '../../../gql/generated/graphql';
import { MediaListObj, fileSend, messageSend } from '../../../gql/messageGql';

import { ChatMessage } from '../TextModal';

import FileSelect from './FileSelect';
import TemplateSelect from './TemplateSelect';

import { marketUrl } from '../../../config';
import { PermissionEnum } from '../../../constants/permissions';
import { useUser } from '../../../hooks/useUser';
import { AbilityContext, NotesContext } from '../../../libs/contextLib';
import { DealMediaEdit, GetUploadUrlResult } from '../../../types/media';
import { getFileNameAndExtension, normalizeFileName } from '../../../utils/files';
import { transformMetadata } from '../../../utils/media';
import { isAdvisor, isFinancialSpecialist, isLeadAdvisor } from '../../../utils/permissions';
import CopyButtonV2 from '../../CopyButton/CopyButtonV2';

interface Props {
  deal: Deal | null;
  phone: string;
  recipient: Customer;
  messageList: ChatMessage[];
  setMessageList: Dispatch<SetStateAction<ChatMessage[]>>;
}

const MessageInput = ({ deal, phone, recipient, messageList, setMessageList }: Props) => {
  const user = useUser();
  const abilities = useContext(AbilityContext);
  const { notesRefetch } = useContext(NotesContext);

  const isAdmin = abilities.has(PermissionEnum.SuperUser);
  const client = useApolloClient();

  const [sendMessage] = useMutation(messageSend);
  const [mediaInsert] = useMediaInsertMutation();
  const [sendFile] = useMutation(fileSend);

  const [loading, setLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<DealMediaEdit[]>([]);

  const maxLength = 1400;

  const initialValues = {
    text_message: '',
  };

  const [shouldWriteInput, setShouldWriteInput] = useState(true);

  const onClear = (media: DealMediaEdit, notSent?: boolean) => {
    if (notSent) {
      setFiles(files.filter((f) => f.fileName !== media.fileName));
    } else {
      setFiles([]);
    }
  };

  const onFilesSelected = async (fileList: FileList) => {
    const fileArr: File[] = Array.from(fileList);
    fileArr.forEach((f) => {
      if (f.size > 4194304) {
        toast.error(`${f.name} can't be sent because it is bigger than 4MB.`);
      }
    });
    const filesArr = fileArr.filter((f) => f.size < 4194304);
    const selectedMedia: DealMediaEdit[] = await Promise.all(
      filesArr.map(async (file) => {
        const normalizedFileName = normalizeFileName(file.name);
        const { fileName, extension } = getFileNameAndExtension(normalizedFileName);

        return {
          selectedFile: file,
          sourceFileName: normalizedFileName,
          fileName,
          extension,
          type: DealMediaTypeEnum.Other,
        };
      }),
    );
    setFiles(selectedMedia);
  };

  const addMessageOnSend = (
    body?: Maybe<string>,
    media?: Maybe<string>,
    fileType?: Maybe<string>,
  ) => {
    return {
      date: new Date(),
      author: user.name,
      fromCustomer: false,
      data: {
        text: body ?? '',
        dateCreated: new Date()
          .toLocaleString('en-US', {
            day: 'numeric',
            month: 'short',
            weekday: 'short',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          })
          .replace(/,/g, (a, b) => {
            return b > 5 ? ' at' : ',';
          }),
        mediaListObj: media
          ? {
              data: [
                {
                  url: media ?? '',
                  contentType: fileType === '.pdf' ? 'application/pdf' : 'image/jpeg',
                },
              ],
            }
          : null,
      },
    } as ChatMessage;
  };

  const onMessageSend = async (message: ChatMessage) => {
    setLoading(true);
    if (files && files.length) {
      await Promise.all(
        files.map(async (file) => {
          if (!deal?.id) {
            return;
          }

          const { data } = await client.query<GetUploadUrlResult>({
            query: GetUploadUrlDocument,
            variables: {
              dealId: deal.id,
              fileName: file.sourceFileName,
            },
          });

          const { url, key } = data.getUploadUrl;
          await fetch(url, {
            method: 'PUT',
            body: file.selectedFile,
          });

          await mediaInsert({
            variables: {
              key,
              dealId: deal.id,
              type: file.type,
              metadata: transformMetadata(file.metadata),
            },
          }).then((res) => {
            return sendFile({
              variables: {
                file: key,
                dealPhoneNumber: phone,
                id: deal.id,
              },
            })
              .then(() => {
                const newMessage = addMessageOnSend(
                  undefined,
                  res.data?.mediaInsert?.signed_url,
                  res.data?.mediaInsert?.key?.slice(-4),
                );
                messageList.push(newMessage);
                toast.success(`Successfully sent: ${file.sourceFileName}`);
                onClear(file, false);
                notesRefetch();
              })
              .catch((e) => toast.error(`Couldn't send ${file.sourceFileName}. ${e.message}`));
          });
        }),
      );
    }
    if (message.data.text) {
      sendMessage({
        variables: {
          message: message.data.text,
          dealPhoneNumber: phone,
          id: deal?.id,
        },
      })
        .then(() => {
          const newText = addMessageOnSend(message.data.text);
          setMessageList([...messageList, newText]);
          toast.success('Message Sent');
          notesRefetch();
        })
        .catch((e) => {
          toast.error(e.message);
        });
    }
    setMessageList([...messageList]);
    setLoading(false);
  };

  const callOnMessageSend = (values: { text_message: string }) => {
    onMessageSend({
      date: new Date(),
      fromCustomer: false,
      author: 'me',
      data: {
        text: values.text_message,
        mediaListObj: files.length
          ? ({
              data: [
                {
                  url: files[0].signed_url,
                  contentType: files[0].fileName,
                },
              ],
            } as MediaListObj)
          : null,
      },
    });
  };

  const canSeeShareLink =
    isAdmin || isAdvisor(abilities) || isLeadAdvisor(abilities) || isFinancialSpecialist(abilities);

  const shareLink = (() => {
    const userId = user.id.split('|')[1];

    let link: string = marketUrl;
    if (isAdmin || isAdvisor(abilities)) {
      link += `?advisor_id=${userId}`;
    } else if (isFinancialSpecialist(abilities)) {
      link += `?financial_specialist_id=${userId}`;
    }

    return link;
  })();

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={() => undefined}>
      {({ setFieldValue, values, handleChange }) => (
        <Box
          bgColor="gray.200"
          w="100%"
          h="35%"
          p={2}
          borderRadius="0px 0px 15px"
          margin={0}
          borderColor="gray.200"
        >
          <Textarea
            resize="none"
            h="57% !important"
            size="lg"
            value={values.text_message}
            border="0px"
            borderRadius="10px"
            bgColor="white"
            placeholder="Write a message..."
            name="text_message"
            fontSize="16px"
            maxLength={maxLength}
            onChange={shouldWriteInput ? handleChange : undefined}
            onKeyDown={(e: KeyboardEvent<HTMLTextAreaElement>) => {
              if (e.shiftKey || e.key !== 'Enter') {
                setShouldWriteInput(true);
              } else {
                setShouldWriteInput(false);
                callOnMessageSend(values);
                setFieldValue('text_message', '');
              }
            }}
          />
          <Text fontSize="sm" mt={1}>
            {`${values.text_message.length || 0}/${maxLength} characters`}
          </Text>
          <HStack justifyContent="space-between">
            <HStack h="60px" overflowY="visible">
              <Input
                type="file"
                display="none"
                id="select-files"
                multiple
                accept=".pdf, .jpg, .png"
                onChange={(e) => {
                  if (e.target.files) {
                    onFilesSelected(e.target.files);
                  }
                }}
              />
              <TemplateSelect client={recipient ?? deal?.customer} />
              <IconButton
                aria-label="Add File"
                variant="primary"
                size="sm"
                onClick={() => document.getElementById('select-files')?.click()}
                icon={<Icon as={MdAdd} color="white" w={6} h={6} />}
              />
              {canSeeShareLink && (
                <CopyButtonV2
                  size="sm"
                  variant="primary"
                  color="white"
                  value={shareLink}
                  icon={<BsLink45Deg size={23} />}
                  keepLowercase
                />
              )}
              {files.map((media, index) => {
                if (index < 3) {
                  return <FileSelect media={media} onClear={() => onClear(media, true)} />;
                }
                if (index === 3) {
                  return (
                    <Box
                      position="relative"
                      bgColor="blue.300"
                      border="1px solid"
                      borderColor="blue.400"
                      borderRadius="5px"
                      w="40px"
                      h="40px"
                      fontSize="16px"
                      display="flex"
                      justifyContent="center"
                    >
                      <Box alignSelf="center">+{files.length - 3}</Box>
                    </Box>
                  );
                }
                return null;
              })}
            </HStack>
            <Button
              size="md"
              isDisabled={!values.text_message && files.length < 1}
              onClick={() => {
                callOnMessageSend(values);
                setFieldValue('text_message', '');
              }}
            >
              {!loading ? 'SEND' : <Spinner />}
            </Button>
          </HStack>
        </Box>
      )}
    </Formik>
  );
};

export default MessageInput;
