import { useMemo, useState } from 'react';

import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { BiChevronDown, BiChevronUp, BiTrash } from 'react-icons/bi';
import { MdPersonAdd } from 'react-icons/md';
import { toast } from 'react-toastify';

import {
  Maybe,
  Pod,
  User,
  useGetUsersLazyQuery,
  useSoftDeleteUserMutation,
} from '../../gql/generated/graphql';

import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination';
import Modal from '../../components/shared/Modal';

import CreateUserModal from './CreateUserModal/CreateUserModal';

import usePagination from '../../hooks/usePagination';
import { formatDate } from '../../libs/utils';

const UsersTable = () => {
  const paginationContext = usePagination();

  const [sortColumn, setSortColumn] = useState<'date' | 'name'>('name');
  const [isSortAscending, setIsSortAscending] = useState(true);

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [users, setUsers] = useState<User[]>([]);
  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const createModal = useDisclosure();
  const deleteModal = useDisclosure();

  const [softDeleteUser, { loading: softDeleteLoading }] = useSoftDeleteUserMutation();
  const [getUsers, { loading }] = useGetUsersLazyQuery({
    variables: {
      currentPage,
      itemsPerPage,
      sortColumn,
      sortDirection: isSortAscending ? 'asc' : 'desc',
    },
    onCompleted: (data) => {
      paginationContext.setTotalRecords(data.getUsers?.totalRecords ?? 0);
      setUsers((data?.getUsers?.results as User[]) ?? []);
    },
    onError: (error) => {
      toast.error(`Couldn't load Users. Please refresh. Error: ${error.message}`);
    },
  });

  // Fetch users on mount
  useMemo(() => {
    getUsers();
  }, []);

  const handleDelete = async (id: string) => {
    try {
      await softDeleteUser({
        variables: { id },
      });

      // remove deleted user from the list
      setUsers(users?.filter((u) => u?.id !== id));
      toast.success('User Deleted');
    } catch (error) {
      toast.error('Failed to delete user.');
    } finally {
      deleteModal.onClose();
    }
  };

  type HeaderColumnProps = {
    columnName: string;
    width: string;
  };
  const HeaderColumn = ({ columnName, width }: HeaderColumnProps) => {
    const canSort = columnName === 'name' || columnName === 'date';

    return (
      <Th
        pl={columnName === 'name' ? 1 : undefined}
        w={width}
        color="white"
        cursor={canSort ? 'pointer' : 'default'}
        onClick={() => {
          if (canSort) {
            // Toggle sort direction if already sorting by name, otherwise default to ascending
            if (sortColumn === columnName) {
              setIsSortAscending(!isSortAscending);
            } else {
              setSortColumn(columnName);
              setIsSortAscending(true);
            }
          }
        }}
      >
        {columnName.replace('_', ' ').toUpperCase()}
        {sortColumn === columnName && canSort && (
          <Icon as={isSortAscending ? BiChevronDown : BiChevronUp} marginLeft={1} />
        )}
      </Th>
    );
  };

  return (
    <Box>
      <Flex
        h={16}
        w="100%"
        bgColor="oxfordBlue"
        borderRadius="10px 10px 0 0"
        alignItems="center"
        justifyContent="flex-end"
        px={8}
      >
        <Button
          variant="secondary"
          leftIcon={<MdPersonAdd size="20px" />}
          onClick={createModal.onOpen}
        >
          Add User
        </Button>
      </Flex>
      <Box overflowX="auto">
        <Table overflowX="auto">
          <Thead cursor="pointer" color="white" bgColor="queenBlue" w="100%" px={6} height="55px">
            <HeaderColumn width="1%" columnName="" />
            <HeaderColumn width="15%" columnName="name" />
            <HeaderColumn width="15%" columnName="date" />
            <HeaderColumn width="15%" columnName="team" />
            <HeaderColumn width="15%" columnName="roles" />
            <HeaderColumn width="15%" columnName="phone_number" />
            <HeaderColumn width="15%" columnName="twilio_number" />
            <HeaderColumn width="5%" columnName="actions" />
          </Thead>
          <Tbody>
            {users?.map((u) => {
              if (!u) {
                return null;
              }

              return (
                <Tr
                  key={`user-${u.name}`}
                  height="55px"
                  borderBottom="2px"
                  borderX="1px"
                  borderColor="backgroundGray"
                  cursor="pointer"
                  _hover={{ bgColor: 'hoverGray' }}
                  bgColor="white"
                >
                  <Td />
                  <Td pl={1} w="15%">
                    {u.name}
                  </Td>
                  <Td w="15%"> {formatDate(u?.created_at?.toString())}</Td>
                  <Td w="15%"> {u?.pods?.map((pod: Maybe<Pod>) => pod?.name).join(', ')}</Td>
                  <Td w="15%">{u?.auth0_roles?.roles?.map((role) => role?.name).join(', ')}</Td>
                  <Td w="15%"> {u?.phone_number}</Td>
                  <Td w="15%"> {u?.twilio_number}</Td>

                  <Td w="10%">
                    <Flex justifyContent="center">
                      <IconButton
                        icon={<BiTrash />}
                        variant="iconHover"
                        size="xs"
                        color="errorsRed"
                        aria-label="Delete User"
                        onClick={(e) => {
                          e.stopPropagation();
                          setUserToDelete(u);
                          deleteModal.onOpen();
                        }}
                      />
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
      <Box mb="150px">
        <Pagination
          filters={{
            currentPage,
            itemsPerPage,
          }}
          parentSetCurrentPage={setCurrentPage}
          parentSetItemsPerPage={setItemsPerPage}
          numItemsShown={users.length}
          paginationContext={paginationContext}
          itemsPerPageOptions={[50, 100]}
          borderTop="1px solid"
          borderColor="lightGray"
        />
      </Box>
      <Modal
        title="Delete User Confirmation"
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.onClose}
        leftButtons={
          <Button
            variant="warning"
            onClick={() => {
              setUserToDelete(null);
              deleteModal.onClose();
            }}
          >
            CANCEL
          </Button>
        }
        rightButtons={
          <Button
            variant="boot"
            onClick={async () => {
              if (!userToDelete?.id) {
                return;
              }

              await handleDelete(userToDelete.id);
            }}
            loadingText="Delete"
            isLoading={softDeleteLoading}
          >
            Delete
          </Button>
        }
      >
        <Text align="center">Are you sure you want to delete {userToDelete?.name}?</Text>
      </Modal>
      <CreateUserModal
        isOpen={createModal.isOpen}
        onClose={async () => {
          createModal.onClose();
          await getUsers({ fetchPolicy: 'network-only' }); // We want to see the new user in the list
        }}
      />
      <Loader isLoading={loading} />
    </Box>
  );
};

export default UsersTable;
